import React from 'react';
import {Badge} from 'primereact/badge';
import { getSeverity } from '../../Constant/general';
import { formatDecimal } from '../../Hooks/helper';

const RRTemplate = ({className, rr}) => {
  return (
    <Badge value={`${formatDecimal(rr)} %`} style={{backgroundColor: getSeverity(rr, 'RR'), color: 'white'}} className={className}/>
  )
}

export default RRTemplate
