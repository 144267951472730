import { Card } from 'primereact/card';
import React from 'react';
import RRTemplate from '../../Components/Tag/RRTemplate';
import { getMotivationRR, getSeverity } from '../../Constant/general';
import { Tag } from 'primereact/tag';
import { Divider } from 'primereact/divider'
 
const DashJumbotron = ({nickName, nilaiRR=70}) => {
  return (
    <div className='flex lg:flex-row flex-column gap-2'>
      <Card className='w-full'>
        <div className='flex lg:flex-row md:flex-row sm:flex-row flex-column lg:gap-4 md:gap-4 gap-3 lg:align-items-center md:align-items-center sm:align-items-center'>
          <img src='../img/welcome-teacher.png' alt='welcome' className='max-w-20rem w-8'/>
          <div>
              <h3 className='lg:text-3xl md:text-3xl sm:text-2xl text-sm m-0 p-0'>Welcome Back, {nickName}!</h3>
              <p className='lg:text-xl md:text-xl sm:text-xl text-xs m-0 p-0'>
              Jangan lupa mengawali hari dengan bacaan bismillah, ya. Oh iya, kamu punya nilai <span className='font-italic font-bold'>Rate Retention (RR)</span> mencapai <RRTemplate rr={nilaiRR} className={'vertical-align-middle text-xs lg:text-lg md:text-md sm:text-sm'}/> , {getMotivationRR(nilaiRR)}. Semoga kegiatan belajar mengajarnya dilancarkan dan tetap semangat...
              </p>
          </div>
        </div>
      </Card>
      <Card className='lg:w-4 w-full'>
        <div className='flex flex-column gap-2 justify-content-center'>
          <div className='flex flex-row justify-content-between align-items-center'>
            <h3 className='m-0 p-0'>Info RR</h3>
            <RRTemplate rr={nilaiRR} className={'vertical-align-middle text-xl'}/>
          </div>
          <div className='flex lg:flex-column md:flex-row sm:flex-row flex-column gap-1'>
            <div className='flex align-items-center lg:text-base md:text-md sm:text-sm text-xs gap-2'>
              <Tag value="o" style={{backgroundColor: getSeverity(55, 'RR')}}/>
              <p className='m-0 p-0'>Merah: retensi rendah atau  <span className='font-bold'>Low Retention</span></p>
            </div>
            <div className='flex align-items-center lg:text-base md:text-md sm:text-sm text-xs gap-2'>
              <Tag value="o" style={{backgroundColor: getSeverity(70, 'RR')}}/>
              <p className='m-0 p-0'>Kuning: retensi sedang atau <span className='font-bold'>Medium Retention</span></p>
            </div>
            <div className='flex align-items-center lg:text-base md:text-md sm:text-sm text-xs gap-2'>
              <Tag value="o" style={{backgroundColor: getSeverity(85, 'RR')}}/>
              <p className='m-0 p-0'>Hijau: retensi tinggi atau <span className='font-bold'>High Retention</span></p>
            </div>
          </div>
          <div>
            <h5 className='m-0 p-0 font-italic'>Note:</h5>
            <p className='m-0 p-0 text-sm font-normal'>Standar Rate Retention Edufic adalah 75%</p>
          </div>
          <Divider align='center' className='m-0 p-0'><span className='font-italic text-sm text-gray-400'>Data Tim kurikulum</span></Divider>
        </div>
      </Card>
    </div>
  )
}

export default DashJumbotron
